/**@jsx jsx*/
import React, { useEffect, useState } from "react"
import { useGlobalState, useGlobalDispatch } from "../utilities/globalContext"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import axios from "axios"
import { Row, Col } from "boostly-ui2"
import { useTransition, a } from "react-spring"
import {
  ButtonSecondary,
  ButtonDelete,
  ButtonTertiary,
} from "../components/buttons"

import { TitleSecondary, TitleTertiary } from "../components/titles"
import Card from "../components/card"
import { serverURL } from "../../variables"

const tableRow = css`
  padding-top: 6px;
`
const InlineLink = styled.a`
  ${tableRow};
  display: inline-block;
`
const HideWhenMobileCSS = css`
  @media (max-width: 480px) {
    display: none;
  }
`

const getProductsURL = id => `${serverURL}/api/users/${id}/products`
const deleteProductUrl = `${serverURL}/api/users/products`
const Products = ({ className, message, ...props }) => {
  const { user } = useGlobalState()
  const globalDispatch = useGlobalDispatch()
  const [products, setProducts] = useState([])
  // const fadeTransitionSpring = useTransition(products, product => product.key, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  // })
  const transition = useTransition(products, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  useEffect(() => {
    axios
      .get(getProductsURL(user.id), {
        headers: {
          "Conent-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then(res => {
        const products = res.data
        setProducts(products)
        globalDispatch({ type: "SET_PRODUCTS", products: products })
      })
      .catch(e => {
        console.log(`didnt work`)
      })
  }, [])
  const handleDelete = async id => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios({
          method: "delete",
          url: deleteProductUrl,
          data: {
            id: id,
          },
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        })
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }
  const handleDeleteSuccess = id => {
    setProducts(prev => prev.filter(e => e.id !== id))
  }
  const handleDeleteFailure = id => {
    console.log("There was an error deleting the product. Try again.")
  }
  return (
    <Card className={className}>
      <TitleSecondary>
        {!message ? `Year to date earnings: $20` : message}
      </TitleSecondary>
      <hr />
      <Col
        css={css`
          height: auto;
          box-sizing: border-box;
        `}
        space="between"
      >
        <TitleTertiary>
          {products.length == 0 && "You have no products!"}
        </TitleTertiary>
        {products.length > 0 && (
          <table
            css={css`
              table-layout: auto;
              width: 100%;
            `}
          >
            <tbody>
              <tr
                css={css`
                  text-align: left;
                `}
              >
                <th>Name</th>
                <th css={HideWhenMobileCSS}>Type</th>
                <th>Price</th>
              </tr>
              {transition((values, item) => {
                const { name, price, id, type } = item
                return (
                  <a.tr css={tableRow} style={values}>
                    <td>
                      <InlineLink href={`/pay?id=${id}`}>{name}</InlineLink>
                    </td>
                    <td css={(tableRow, HideWhenMobileCSS)}>{type}</td>
                    <td css={tableRow}>{price}.00</td>
                    <td
                      css={css`
                        ${tableRow};
                        text-align: right;
                      `}
                    >
                      <ButtonDelete
                        handleDelete={() => handleDelete(id)}
                        successCallback={() => handleDeleteSuccess(id)}
                        failureCallback={() => handleDeleteFailure(id)}
                      />
                    </td>
                  </a.tr>
                )
              })}
              {/* {products.map((product, i) => {
                const { name, price, id } = product
                return (
                  <tr css={tableRow} space="between" key={product.id}>
                    <td>
                      <InlineLink href={`/pay?id=${id}`}>{name}</InlineLink>
                    </td>
                    <td css={(tableRow, HideWhenMobileCSS)}>Direct Charge</td>
                    <td css={tableRow}>{price}.00</td>
                    <td
                      css={css`
                        ${tableRow};
                        text-align: right;
                      `}
                    >
                      <ButtonDelete
                        handleDelete={() => handleDelete(product.id)}
                        successCallback={() => handleDeleteSuccess(product.id)}
                        failureCallback={() => handleDeleteFailure(product.id)}
                      />
                    </td>
                  </tr>
                )
              })} */}
            </tbody>
          </table>
        )}
        <ButtonSecondary
          css={css`
            margin-top: 12px;
          `}
          to="/create-product"
        >
          Create New Product
        </ButtonSecondary>
      </Col>
    </Card>
  )
}

export default Products
