/**@jsx jsx*/
import React, { useEffect, useState } from "react"
import { useGlobalState, useGlobalDispatch } from "../utilities/globalContext"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import axios from "axios"
import { Row, Col } from "boostly-ui2"
import { Link, navigate } from "@reach/router"
import { a, useSpring, config } from "react-spring"

import Layout from "../components/layout"
import { TitlePrimary, TitleSecondary } from "../components/titles"
import Card from "../components/card"
import { ButtonPrimary } from "../components/buttons"
import Products from "../components/products"
import { serverURL, clientURL } from "../../variables"

const logoutURL = `${serverURL}/api/users/`
const members = [
  {
    name: "Jack",
    email: "jack@jackattack.com",
    status: "member",
    expiresOn: new Date(`2020`, `11`, `05`),
  },
  {
    name: "George",
    email: "george@jackattack.com",
    status: "member",
    expiresOn: new Date(`2019`, `12`, `20`),
  },
  {
    name: "Ali",
    email: "ali@g.com",
    status: "donor",
    expiresOn: null,
    donation: 2.0,
  },
  {
    name: "Mike",
    email: "michael@jackattack.com",
    status: "member",
    expiresOn: new Date(`2020`, `05`, `11`),
  },
  {
    name: "Jules",
    email: "jack@jackattack.com",
    status: "donor",
    expiresOn: null,
    donation: 5.0,
  },
  {
    name: "Hack",
    email: "news@jackattack.com",
    status: "member",
    expiresOn: new Date(`2020`, `10`, `05`),
  },
]

const Layout2 = styled(Layout)`
  grid-template:
    minmax(1rem, 1.5rem)
    minmax(auto, 1fr)
    / minmax(0.5rem, 1fr) minmax(auto, 768px) minmax(0.5rem, 1fr);
`
const Options = styled.section`
  z-index: 10;
  position: absolute;
  top: 20px;
  left: -5px;
`
const TitlePrimary2 = styled(TitlePrimary)`
  margin-top: 20px;
  text-align: center;
`
const Dashboard = () => {
  const { user } = useGlobalState()
  const globalDispatch = useGlobalDispatch()
  const [message, setMessage] = useState(null)

  const fadeinSpring = useSpring({
    from: {
      transform: "translate3d(0,-40px,0)",
      opacity: 0,
    },
    to: {
      transform: "translate3d(0,0,0)",
      opacity: 1,
    },
    delay: 500,
  })
  //   useEffect(() => {
  //     setTimeout(() => {
  //       setShow(true)
  //     }, 400)
  //   })

  // Redirect them to login page if not logged in
  useEffect(() => {
    console.log(user)
    if (!user) {
      typeof window !== "undefined" && (window.location.href = clientURL)
    }
  }, [])

  // Retrieve information from Stripe
  useEffect(() => {
    if (!user.stripeUserId) {
      setMessage("No stripe user id")
    }
  })

  // Handle log out
  const handleClick = () => {
    try {
      const res = axios.delete(logoutURL)
      globalDispatch({ type: "LOGOUT" })
      typeof window !== "undefined" && (window.location.href = clientURL)
    } catch (e) {}
  }
  return (
    <Layout2>
      <Options>
        <a href="/stripe-setup">
          <ButtonPrimary>Click here to setup integrations</ButtonPrimary>
        </a>
      </Options>
      <Col
        css={css`
          z-index: 2;
          grid-column-start: 2;
          grid-row: 2 /4;
        `}
      >
        <a.div style={fadeinSpring}>
          <Row
            x
            y
            css={css`
              grid-area: "title";
              margin-top: 60px;
              @media (max-width: 500px) {
                margin-top: 40px;
              }
            `}
          >
            <TitlePrimary2>👋 there, {user && user.name}!</TitlePrimary2>
          </Row>
          <Products
            css={css`
              margin-top: 20px;
            `}
            message="Products"
          />
          <Card
            css={css`
              margin: 12px 0px;
            `}
          >
            <TitleSecondary>Members</TitleSecondary>
            {members.map((m, i) => (
              <h3 key={i}>{m.name}</h3>
            ))}
            <button onClick={handleClick}>Log out</button>
          </Card>
        </a.div>
      </Col>
    </Layout2>
  )
}

export default Dashboard
